<template>
  <v-data-table
    class="custom-table font-inter mt-6"
    v-model="selected"
    hide-default-footer
    show-select
    :header-props="{ 'sort-icon': 'mdi-chevron-down' }"
    :headers="headers"
    :items="itemsPerView"
    :item-key="itemKey"
  >
    <template v-slot:[`headers.name`]="{ header }">
      <span class="header_text">{{ header.text }}</span>
    </template>
    <template v-slot:[`item.name`]="{ item }">
      <span class="text-subtitle-2 font-weight-bold">{{ item.name }}</span>
    </template>
    <template v-slot:[`item.testcases`]="{ item }">
      <td class="d-flex align-center">
        <div class="text-start">
          <div class="text-subtitle-3">{{ item.customFields.case_count }} {{ $t('plans.testRuns.testCases') }}</div>
        </div>
      </td>
    </template>
    <template v-slot:[`item.testruns`]="{ item }">
      <td class="d-flex align-center">
        <div class="text-start">
          <div class="text-subtitle-2 font-weight-bold">{{ item.testruns }} {{ $t('plans.testRuns.testRuns') }}</div>
        </div>
      </td>
    </template>
    <template v-slot:[`item.configuration`]="{ item }">
      <div class="d-flex flex-row align-center justify-end">
        <button
          class="btn-add-configuration"
          @click="isOpenAddConfigurations = true"
          v-if="!item.configurations || item.configurations.length <= 0"
        >
          <v-icon>mdi-plus</v-icon> {{ $t('add') }}
        </button>
        <v-menu left offset-y class="font-inter" v-else>
          <template v-slot:activator="{ on }">
            <button class="btn-configuration font-inter text-none" v-on="on">
              {{ item.configurations[0].title }} <v-icon color="#344054">mdi-chevron-down</v-icon>
            </button>
          </template>
          <v-list dense class="configurations-menu text-left font-inter">
            <v-subheader class="font-inter pt-2 px-4">{{ $t('configurations') }}</v-subheader>
            <v-list-item
              v-for="(group, index) of item.configurations"
              :key="index"
              class="pt-2 px-4 d-flex align-center"
            >
              <span>{{ group.title }}</span>
              <button class="btn-edit d-flex ml-1" @click="$emit('edit', item)"><iconEdit /></button>
              <button class="btn-delete d-flex ml-1" @click="$emit('delete', item)"><iconDelete /></button>
            </v-list-item>
            <v-list-item>
              <button class="btn-add-configuration" @click="isOpenAddConfigurations = true">
                <v-icon>mdi-plus</v-icon> {{ $t('add') }}
              </button>
            </v-list-item>
          </v-list>
        </v-menu>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
        <v-btn icon>
          <v-icon color="gray-500"> mdi-minus-box-outline </v-icon>
        </v-btn>
        <add-configuration-dialog
          :open="isOpenAddConfigurations"
          @add="handleAddConfigurations(item, $event)"
          @close="isOpenAddConfigurations = false"
        />
      </div>
    </template>
    <template v-slot:[`item.priority`]="{item}">
      <span :class=getColor(item.customFields.priority)>{{ item.customFields.priority }}</span>
    </template>
    <template v-slot:[`item.status`]="{item}">
      <span :class=getColor(item.customFields.status)>{{ item.customFields.status }}</span>
    </template>
    <template v-slot:[`item.milestone`]="{item}">
      <span :class=getColor(item.customFields.milestone)>{{ item.customFields.milestone }}</span>
    </template>
    <template v-slot:[`item.tags`]="{item}">
      <span :class=getColor(item.customFields.tags)>{{ item.customFields.tags }}</span>
    </template>
    <template v-slot:[`item.creationdate`]="{item}">
      <span class="">{{ item.createdAt }}</span>
    </template>
    <template v-slot:[`item.progress`]="{item}">
      <div class="d-flex flex-row align-center justify-space-between">
        <v-progress-linear
          rounded
          color="#0c2ff3"
          background-color="#F2F4F7"
          :height="8"
          :value="item.customFields.progress"
        />
        <span class="font-weight-regular ml-3">{{ item.customFields.progress }}%</span>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import AddConfigurationDialog from '@/views/Tests/Plans/Components/Configuration/Index';

import iconEdit from '@/assets/svg/edit-16x16.svg';
import iconDelete from '@/assets/svg/delete-16x16.svg';

export default {
  name: 'test-runs-list',

  components: {
    AddConfigurationDialog,
    iconEdit,
    iconDelete,
  },
  props: {
    tab: {
      type: String,
      default: 'all',
    },
    data: {
      type: Array,
    },
    selectedRuns: {
      type: Array,
    },
    headers: {
      type: Array,
    },
    filteredItems: {
      type: Array,
    },
    rowClass: {
      type: Function,
    },
  },
  data() {
    return {
      itemKey: 'name',
      selected: this.selectedRuns,
      isAllUserChecked: false,
      isSelectedUserChecked: false,
      isOpenAddConfigurations: false,
    };
  },
  computed: {
    itemsPerView() {
      if (this.tab === 'all') {
        return this.data.filter((item) => !item.selected);
      } else {
        return this.data.filter((item) => item.selected);
      }
    },
  },
  watch: {
    tab(newValue) {
      this.toggleUsers();
    },
    selected(newValue) {
      this.$emit('selectTestRun', newValue)
    }
  },
  methods: {
    getColor(priority) {
      switch(priority){
        case "High":
        case "Failed":
          return "font-weight-bold red--text text--lighten-1"
        case "Medium":
        case "Rerun":
          return "font-weight-bold orange--text text--lighten-1"
        case "Low":
        case "Active":
          return "font-weight-bold green--text text--lighten-1"
        case "Passed":
          return "font-weight-bold deep-purple--text text--lighten-1"
        case "In progress":
          return "font-weight-bold grey--text text--lighten-1"
      }
    },
    toggleUsers() {
      const isSelected = this.tab === 'all' ? this.isAllUserChecked : this.isSelectedUserChecked;
      this.data.forEach((user) => {
        const condition = this.tab === 'all' ? !user.selected : user.selected;
        if (condition) {
          this.$set(user, 'toBeSelected', isSelected);
        }
      });
    },
    toBeSelectedHandler() {
      const filteredUsers =
        this.tab === 'all' ? this.data.filter((user) => !user.selected) : this.data.filter((user) => user.selected);
      const allTrue = filteredUsers.every((user) => user.toBeSelected);

      if (this.tab === 'all') {
        this.isAllUserChecked = allTrue;
      } else {
        this.isSelectedUserChecked = allTrue;
      }
    },
    handleAddConfigurations(item, value) {
      item.configurations = value;
      this.isOpenAddConfigurations = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-add-configuration {
  padding: 0;
  background: transparent;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #667085;

  display: inline-flex;
  align-items: center;
  gap: 8px;

  &::before {
    display: none;
  }

  .v-icon {
    color: #667085;
  }
}

.btn-add-configuration:hover {
  color: #0c2ff3;

  .v-icon {
    color: #0c2ff3;
  }
}

.configurations-menu {
  .v-subheader {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #0c111d;
  }

  .btn-edit {
    color: #667085;
  }

  .btn-delete {
    color: #f4284e;
  }

  .btn-add-configuration {
    color: #0c2ff3;
  }
}
</style>