<template>
  <div class="checkbox-container d-flex justify-start align-center">
    <v-checkbox v-model="isSelected" color="blue" hide-details dense>
      <template v-slot:label>
        <span>{{ value.title }}</span>
      </template>
    </v-checkbox>
    <button class="btn-edit ml-1" @click.stop="$emit('edit', value)"><iconEdit /></button>
    <button class="btn-delete ml-2" @click.stop="$emit('delete', value)"><iconDelete /></button>
  </div>
</template>

<script>
import iconEdit from '@/assets/svg/edit-16x16.svg';
import iconDelete from '@/assets/svg/delete-16x16.svg';

export default {
  name: 'group-item',

  props: ['value'],
  components: {
    iconEdit,
    iconDelete,
  },
  data() {
    return {
      isSelected: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.checkbox-container:hover {
  .btn-edit,
  .btn-delete {
    display: flex;
  }
}

.btn-edit {
  flex: none;
  width: 16px;
  height: 16px;
  color: #667085;

  display: none;
}

.btn-delete {
  flex: none;
  width: 16px;
  height: 16px;
  color: #f4284e;

  display: none;
}

.v-input--checkbox.v-input--dense {
  margin-top: 0;
}

.v-input--selection-controls {
  padding-top: 0px;
}
</style>