<template>
  <v-expansion-panel>
    <v-expansion-panel-header class="pa-0">
      <group-item :value="value" @edit="$emit('edit', value)" @delete="$emit('delete', value)" />
      <div class="btn-add-configuration relative text-right mr-2">
        <v-btn class="ml-auto text-capitalize font-inter pa-2" small text color="blue" @click.stop="handleOpenAdd">
          <v-icon>mdi-plus</v-icon> {{ $t('add') }}
        </v-btn>
        <add-group
          v-if="isOpenAdd"
          :description="$t('E.g.: Windows 11, iOS 16, or Firefox')"
          @add="handleAdd"
          @cancel="isOpenAdd = false"
        />
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <search-field v-model="searchKey" :placeholder="$t('search')" />
      <div class="mt-2 pl-2">
        <group-item
          class="py-2"
          v-for="(item, index) of value.children"
          :key="index"
          :value="item"
          @edit="$emit('editItem', item)"
          @delete="$emit('deleteItem', item)"
        />
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import SearchField from '@/components/Form/SearchField';
import AddGroup from '@/views/Tests/Plans/Components/Configuration/AddGroup';
import GroupItem from '@/views/Tests/Plans/Components/Configuration/GroupItem.vue';

export default {
  name: 'configuration-item',

  components: {
    SearchField,
    AddGroup,
    GroupItem,
  },
  props: ['value'],
  data() {
    return {
      isBrowsersSelected: true,
      searchKey: '',
      isOpenAdd: false,
    };
  },
  computed: {
    panels() {
      return [0];
    },
  },
  methods: {
    handleOpenAdd() {
      this.isOpenAdd = !this.isOpenAdd;
    },
    handleAdd(value) {
      this.$emit('add', value);
      this.isOpenAdd = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-add-configuration {
  flex: 1;
}

.v-application--is-ltr .v-expansion-panel-header__icon {
  margin-left: 8px;
}
</style>