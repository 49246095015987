<template>
  <v-dialog
    v-model="open"
    content-class="create-plan-add-runs-configuration-drawer font-inter"
    fullscreen
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card class="relative">
      <div class="d-flex align-center justify-space-between mb-4">
        <h2 class="black--text">{{ $t('plans.create.testRuns.addConfigurations') }}</h2>
        <v-btn icon @click="$emit('close')">
          <v-icon color="black">mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="mt-8">
        <v-expansion-panels flat v-model="groupPanels">
          <configuration-item
            v-for="(group, index) of groups"
            :key="index"
            :value="group"
            @add="handleAddGroupItem(index, $event)"
            @delete="handleDeleteGroup"
            @editItem="handleEditGroupItem"
            @deleteItem="handleDeleteGroupItem"
          />
        </v-expansion-panels>
        <div class="relative mt-8 text-start">
          <v-btn class="text-none" color="#D0D5DD" @click="handleOpenAddGroup">
            <v-icon class="mr-2">mdi-plus</v-icon>{{ $t('plans.create.testRuns.addGroup') }}
          </v-btn>
          <add-group
            v-if="isOpenAddGroup"
            description="E.g.: OS or Web Browsers"
            @add="handleAddGroup"
            @cancel="isOpenAddGroup = false"
          />
        </div>
      </div>
      <div class="actions-container absolute d-flex align-center py-8">
        <v-btn color="gray-100" depressed class="mr-4 text-capitalize" @click="$emit('close')">
          {{ $t('cancel') }}
        </v-btn>
        <v-btn color="blue" dark depressed class="text-capitalize" @click="$emit('add', groups)">
          {{ $t('add') }}
        </v-btn>
      </div>
    </v-card>
    <confirm-dialog
      v-model="isOpenDeleteGroup"
      :title="`Are you sure you want to delete “${deletingGroup?.title}” configuration group?`"
      :description="`Are you sure you want to delete this configuration group and <strong>${
        deletingGroup?.children?.length ?? 0
      } configurations</strong> in it?<br/>All data will be lost.`"
      :actions="[
        { label: $t('cancel'), color: 'secondary', action: 'close' },
        { label: $t('delete'), color: 'primary', action: 'confirm' },
      ]"
      @close="deletingGroup = null"
      @confirm="handleConfirmDeleteGroup"
    />
    <confirm-dialog
      v-model="isOpenDeleteItem"
      :title="`Are you sure you want to delete “${deletingItem?.title}” configuration?`"
      :description="`Are you sure you want to delete this configuration?<br/>All data will be lost.<br/>The \“${deletingItem?.title}\” configuration is currently associated with 2 test runs.`"
      :actions="[
        { label: $t('cancel'), color: 'secondary', action: 'close' },
        { label: $t('delete'), color: 'primary', action: 'confirm' },
      ]"
      @close="deletingItem = null"
      @confirm="handleConfirmDeleteGroupItem"
    />
  </v-dialog>
</template>

<script>
import ConfigurationItem from '@/views/Tests/Plans/Components/Configuration/ConfigurationItem';
import AddGroup from '@/views/Tests/Plans/Components/Configuration/AddGroup';
import ConfirmDialog from '@/views/Tests/Plans/Components/ConfirmDialog.vue';

export default {
  name: 'add-configuration-dialog',

  components: {
    ConfigurationItem,
    AddGroup,
    ConfirmDialog,
  },
  props: {
    open: Boolean,
  },
  data() {
    return {
      isOpenAddGroup: false,
      groupPanels: [0],
      groups: [
        {
          title: 'Browser',
          children: [
            { title: 'Chrome' },
            { title: 'Opera' },
            { title: 'Safari' },
            { title: 'Firefox' },
            { title: 'Edge' },
          ],
        },
        {
          title: 'Operating Systems',
        },
      ],
      isOpenDeleteGroup: false,
      deletingGroup: null,
      editingGroup: null,
      isOpenDeleteItem: false,
      deletingItem: null,
    };
  },
  watch: {
    deletingGroup(next) {
      if (!next) {
        this.isOpenDeleteGroup = false;
      } else {
        this.isOpenDeleteGroup = true;
      }
    },
    deletingItem(next) {
      if (!next) {
        this.isOpenDeleteItem = false;
      } else {
        this.isOpenDeleteItem = true;
      }
    },
  },
  methods: {
    handleOpenAddGroup() {
      this.isOpenAddGroup = !this.isOpenAddGroup;
    },
    handleAddGroup(value) {
      this.groups = [...this.groups, { title: value }];
      this.isOpenAddGroup = false;
    },
    handleAddGroupItem(index, value) {
      this.groups = this.groups.map((group, i) => {
        if (index !== i) {
          return group;
        }

        group.children = [...group.children, { title: value }];
        return group;
      });
    },
    handleDeleteGroup(value) {
      this.deletingGroup = value;
    },
    handleConfirmDeleteGroup() {
      if (!this.deletingGroup) {
        return;
      }

      this.deletingGroup = null;
    },
    handleEditGroupItem(value) {
      this.editingGroup = value;
    },
    handleDeleteGroupItem(value) {
      this.deletingItem = value;
    },
    handleConfirmDeleteGroupItem() {
      if (!this.deletingItem) {
        return;
      }

      this.deletingItem = null;
    },
  },
};
</script>

<style lang="scss">
.create-plan-add-runs-configuration-drawer {
  width: 485px !important;
}
</style>

<style lang="scss" scoped>
.v-dialog--fullscreen > .v-card {
  padding: 32px !important;
  padding-bottom: 104px !important;
}

.v-input {
  margin-top: 0;
  padding-top: 0;
}

.v-input--dense {
  margin-top: 0;
}

.add-group-container {
  position: static;
}

.actions-container {
  bottom: 0;
  left: 32px;
  right: 32px;

  button {
    flex: 1;
  }
}

.btn-add-configuration {
  flex: 1;
}

.v-application--is-ltr .v-expansion-panel-header__icon {
  margin-left: 8px;
}
</style>