<template>
  <v-card class="add-group-container mt-2 px-3 py-4 text-start" max-width="260" @click="handleClick($event)">
    <div>Name</div>
    <v-text-field class="mt-1 round-8" v-model="name" dense height="40" hide-details="auto" filled />
    <div class="description mt-1">{{ description }}</div>
    <div class="d-flex justify-end mt-4">
      <v-btn width="110" color="gray-100" full-width class="mr-4 text-capitalize" @click.stop="$emit('cancel')">
        {{ $t('cancel') }}
      </v-btn>
      <v-btn width="110" color="blue" dark full-width class="text-capitalize" @click.stop="handleAdd">
        {{ $t('add') }}
      </v-btn>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'add-group',

  props: {
    description: String,
  },
  data() {
    return {
      name: '',
    };
  },
  methods: {
    handleClick(event) {
      event.stopPropagation();
    },
    handleAdd() {
      if (this.name.length <= 0) {
        return;
      }
      this.$emit('add', this.name);
    },
  },
};
</script>

<style lang="scss" scoped>
.add-group-container {
  position: absolute;
  right: 0;
  z-index: 2;
}

.description {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #667085;
}
</style>
